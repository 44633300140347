import React from "react";

// import Layout from "../components/layout";
// import SEO from "../components/seo";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const NotFoundPage = () => (
  <Container fluid className="section">
    <Row>
      <Col className="text-center">
        <h1>Whoops</h1>
        <p>Page Not Found</p>
      </Col>
    </Row>
  </Container>
);

export default NotFoundPage;
